.footer{
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.footerLink{
  transform: scale(.75);
}

/* .footerLink:hover {
  transform: scale(.7);
} */