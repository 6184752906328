* {
  box-sizing: border-box;
}
body {
  background-color: #E7F0F4;
  font-size: 1.5rem;
  color: #35343f;
  width: 100vw;
}
.App {
  display: flex;
  flex-direction: column;
}
.navLinks {
  display: flex;
  width: 100%;
  z-index: 1;
  font-size: 1rem;
  background-color: #35343f;
}
.navLinkContainer {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-left: 2%;
}
h5 {
  margin: 2%;
  color: #36362e;
}
.section-title {
  align-self: center;
  font-size: 3rem;
  margin-bottom: 0;
  margin-top: 5%;
}
.about-me-section {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.icon {
  order: 0;
  margin: 1%;
  max-width: 100px;
  max-height: 100px;
}
.AboutMeIcon {
  width: 35%;
  align-self: center;
  max-width: 250px;
  cursor: pointer;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.customLinkTitle{
  text-align: left;
}
.title {
  color: #ffffff;
  position: relative;
  font-size: 2rem;
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.596);
  margin: 1%;
  max-width: 90vw;
}
.sub{
  margin-top: 5%;
  font-size: 1.25rem;
  font-style: italic;
}
.Banner {
  display: flex;
  justify-content: center;
  align-items: center;
}
  .parallax-banner{
    min-width:100vw;
  }
@media(min-width: 400px) {
  .title {
    font-size: 4rem;
  }
  .sub{
    font-size: 2rem;
  }
}
