.navLink {
  text-decoration: none;
  margin: 2%;
  padding-top: 2%;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  cursor: pointer;
  width: 125px;
}
.navLink:hover {
  color: #f2e964;
}

@media (min-width: 400px) {
  .navLink {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
